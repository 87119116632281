import React, { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: string;
  btnText: string;
}

const AboutUsSection: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`nc-SectionHero relative ${className} mb-20 lg:mb-36`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
        <div className="flex-grow">
          <img className="w-full rounded-none" src={rightImg} alt="" />
        </div>
        <div className="w-screen max-w-full  space-y-5 lg:space-y-7">
          <h2 className="text-xl font-semibold text-[#1C1C25]">
            Welcome To Our Company
          </h2>
          <h2 className="text-3xl !leading-tight font-semibold text-[#3B3B3B] md:text-4xl xl:text-5xl dark:text-neutral-100">
            Local and Reliable Rentals for Your Comfort
          </h2>
          <span className="block !font-medium xl:text-lg text-[#121212B5] dark:text-neutral-400">
            Our Rental Copmany can help you assess your daily rental needs in
            afforadable rates.
          </span>
          <div className="bg-[#EEEEEE] shadow-md rounded-lg p-6 max-w-lg">
            {/* Icon and Title */}
            <div className="flex items-start space-x-4">
              {/* Icon */}
              <div className="bg-blue-100 rounded-full w-10 h-10 flex items-center justify-center">
                <svg
                  width="55"
                  height="56"
                  viewBox="0 0 55 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="0.0373535"
                    width="55"
                    height="55"
                    rx="27.5"
                    fill="#2873AC"
                    fill-opacity="0.2"
                  />
                  <path
                    d="M33.42 22.5974C33.6156 22.4018 33.8478 22.304 34.1167 22.304C34.3856 22.304 34.6178 22.414 34.8133 22.634C35.0089 22.854 35.1189 23.0985 35.1433 23.3674C35.1678 23.6362 35.0822 23.8685 34.8867 24.064L26.7467 33.6707C26.5511 33.9151 26.3067 34.0374 26.0133 34.0374C25.72 34.0374 25.4756 33.9396 25.28 33.744L20.2933 28.684C20.0978 28.4885 20 28.244 20 27.9507C20 27.6574 20.0978 27.4129 20.2933 27.2174C20.4889 27.0218 20.7333 26.924 21.0267 26.924C21.32 26.924 21.5644 27.0218 21.76 27.2174L25.94 31.4707L33.42 22.5974Z"
                    fill="#2873AC"
                  />
                </svg>
              </div>
              {/* Title and Description */}
              <div>
                <h3 className="text-xl font-semibold text-gray-800">
                  The best Rental Company
                </h3>
                <p className="text-gray-600 mt-1">
                  These tools will allow you to handle tasks such as hanging
                  shelves and assembling furniture.
                </p>
                <div className="mt-6">
                  <ButtonPrimary
                    onClick={() => navigate("/contact")}
                    className="rounded-xl"
                  >
                    Contact Us
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;

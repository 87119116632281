import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/travelhero2.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import MobileSearch from "components/MobileSearchForm/MobileSearch";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionHero3 mb-80 md:mb-52 lg:mb-0 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div
        className="absolute inset-x-0 top-[20%]  md:top-[20%] lg:top-[30%] text-center flex flex-col items-center  mx-auto space-y-3 lg:space-y-3 xl:space-y-5"
        style={{ zIndex: 1 }}
      >
        <span className="text-xl font-normal text-center  text-white  bg-opacity-80 px-1 lg:bg-none lg:bg-opacity-0 lg:mx-0 mx-4">
          Your fresh solution for carshare and rideshare services.
        </span>
        <div
          className="hidden lg:block z-10 mb-12 lg:mb-0 w-full"
          style={{ marginTop: "0px" }}
        >
          <HeroSearchForm />
        </div>
        <div className="block lg:hidden   !mx-auto px-3 w-full">
          <MobileSearch />
        </div>

        {/* <ButtonPrimary
          sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
          fontSize="text-sm sm:text-base lg:text-lg font-medium"
        >
          Keep calm & travel on
        </ButtonPrimary> */}
      </div>

      <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
        <img
          className="absolute inset-0 object-cover w-full"
          src={imagePng}
          alt="hero"
        />
      </div>
    </div>
  );
};

export default SectionHero3;

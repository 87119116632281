import rightImg from "images/about.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import SectionHero2 from "./SectionHero2";
import AboutUsSection from "./AboutUsSection";
import SectionInfoBanner from "./SectionInfoBanner";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Car rental</title>
      </Helmet>
      <h2 className="my-8 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
        About Us
      </h2>
      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 ">
        <AboutUsSection
          rightImg={rightImg}
          heading="About Us"
          btnText=""
          subHeading="Hi, we are Dominique and Cade King. We have been married and lived in Ketchikan, Alaska for over 25 years. We loved raising our four children here, and as each one went on their way we found that our side hobby of renting cars, was something we wanted to get more involved in. We are a local business, supporting our community and those who are blessed to come visit here. Whether you are a local who temporarily needs a car, a traveler who is looking for a long term rental, or a tourist looking for the vacation of a lifetime we want to work with you. Please feel free to text with any concerns or questions you might have."
        />
      </div>
        <SectionInfoBanner />
        {/* <SectionFounder /> */}
        <div className="relative py-16">
          {/* <BackgroundSection /> */}
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>
    </div>
  );
};

export default PageAbout;

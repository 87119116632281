import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import { sendNewsLetterEmail } from "utils/apiUtil";
import toast from "react-hot-toast";
import { globalJson } from "global/global_json";
import { ContactFun } from "api/profile/profile";
import Heading from "components/Heading/Heading";

export interface PageContactProps {
  className?: string;
}

const features = [
  { text: "Cheap Rates" },
  { text: "Free Delivery" },
  { text: "Insurance" },
  { text: "Luxury Cars" },
  { text: "Fuel Efficient Cars" },
  { text: "Trust" },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [getEmail, setEmail] = useState("");
  const [newName, setNewName] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const sendEmail = async (e: React.FormEvent) => {
    setLoading(true);
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!getEmail.trim() || !emailRegex.test(getEmail)) {
      toast.error("Please enter a valid email address");
      setLoading(false);
      return;
    }

    if (getEmail.length === 0 || newName.length === 0 || message.length === 0) {
      toast.error("Fields are required!");
      setLoading(false);
      return;
    }

    // Create the payload with name, email, and message
    const payload = {
      name: newName,
      email: getEmail,
      message: message,
    };

    const result = await ContactFun(payload);

    if (result.error) {
      toast.error(result.error);
    } else {
      toast.success("Sent successfully!");
      setEmail("");
      setNewName("");
      setMessage("");
    }

    setLoading(false);
  };

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact | King Car Rentals car rental</title>
      </Helmet>
      <div className="">
        <h2 className="my-8 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="bg-[#2873AC] max-w-6xl mb-12 rounded-md mx-auto py-8">
          <div className="px-8 lg:px-16 mx-auto flex flex-col lg:flex-row justify-between items-start lg:items-center text-white space-y-6 lg:space-y-0">
            <div className="flex flex-col items-center sm:items-start text-center sm:text-left">
              <div className="flex flex-row items-center space-x-2">
                <div>
                  <svg
                    width="70"
                    height="71"
                    viewBox="0 0 70 71"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.537354"
                      width="70"
                      height="70"
                      rx="35"
                      fill="#EFF1F5"
                    />
                    <path
                      d="M45.3555 39.842C45.6992 40.0139 45.957 40.4006 45.957 40.8303C45.957 40.8733 45.957 40.9592 45.957 41.0452L44.9258 45.5139C44.7969 45.9866 44.4102 46.2874 43.9375 46.2874C32.8945 46.2874 24 37.3928 24 26.3499C24 25.8772 24.3008 25.4905 24.7734 25.3616L29.2422 24.3303C29.3281 24.3303 29.4141 24.2874 29.457 24.2874C29.8867 24.2874 30.2734 24.5452 30.4453 24.9319L32.5078 29.7444C32.5508 29.8733 32.5938 30.0022 32.5938 30.1311C32.5938 30.4749 32.4219 30.7756 32.207 30.9475L29.5859 33.0959C31.1758 36.4475 33.8398 39.1116 37.1914 40.7014L39.3398 38.0803C39.5117 37.8655 39.8125 37.6936 40.1133 37.6936C40.2852 37.6936 40.4141 37.7366 40.543 37.7795L45.3555 39.842Z"
                      fill="#2873AC"
                    />
                  </svg>
                </div>
                <div>
                  <p className="font-semibold">Phone Number</p>
                  <p className="mt-2">{globalJson.phone}</p>
                </div>
              </div>
            </div>

            {/* Divider */}
            <div className="hidden lg:block h-16 border-l border-white"></div>

            {/* Email Address Section */}
            <div className="flex flex-row items-center space-x-2">
              <div>
                <svg
                  width="71"
                  height="71"
                  viewBox="0 0 71 71"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.330078"
                    y="0.537354"
                    width="70"
                    height="70"
                    rx="35"
                    fill="#EFF1F5"
                  />
                  <path
                    d="M44.2676 27.0374C45.3848 27.0374 46.3301 27.9827 46.3301 29.0999V41.4749C46.3301 42.635 45.3848 43.5374 44.2676 43.5374H26.3926C25.2324 43.5374 24.3301 42.635 24.3301 41.4749V29.0999C24.3301 27.9827 25.2324 27.0374 26.3926 27.0374H44.2676ZM44.2676 29.0999H26.3926V30.8616C27.3379 31.678 28.8848 32.8811 32.1504 35.4592C32.8809 36.0178 34.2988 37.3928 35.3301 37.3499C36.3184 37.3928 37.7363 36.0178 38.4668 35.4592C41.7324 32.8811 43.2793 31.678 44.2676 30.8616V29.0999ZM26.3926 41.4749H44.2676V33.5256C43.2793 34.2991 41.8613 35.4163 39.7559 37.092C38.7676 37.8225 37.1348 39.4553 35.3301 39.4124C33.4824 39.4553 31.8066 37.8225 30.8613 37.092C28.7559 35.4163 27.3379 34.2991 26.3926 33.5256V41.4749Z"
                    fill="#2873AC"
                  />
                </svg>
              </div>
              <div>
                <p className="font-semibold">Email</p>
                <p className="mt-2">{globalJson.email}</p>
              </div>
            </div>

            {/* Divider */}
            <div className="hidden lg:block h-16 border-l border-white"></div>

            {/* Office Address Section */}
            <div className="flex flex-row items-center space-x-2">
              <div>
                <svg
                  width="71"
                  height="71"
                  viewBox="0 0 71 71"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.660156"
                    y="0.537354"
                    width="70"
                    height="70"
                    rx="35"
                    fill="#EFF1F5"
                  />
                  <path
                    d="M34.8008 45.8577C28.5273 36.8342 27.4102 35.8889 27.4102 32.5374C27.4102 27.9827 31.0625 24.2874 35.6602 24.2874C40.2148 24.2874 43.9102 27.9827 43.9102 32.5374C43.9102 35.8889 42.75 36.8342 36.4766 45.8577C36.0898 46.4592 35.1875 46.4592 34.8008 45.8577ZM35.6602 35.9749C37.5508 35.9749 39.0977 34.4709 39.0977 32.5374C39.0977 30.6467 37.5508 29.0999 35.6602 29.0999C33.7266 29.0999 32.2227 30.6467 32.2227 32.5374C32.2227 34.4709 33.7266 35.9749 35.6602 35.9749Z"
                    fill="#2873AC"
                  />
                </svg>
              </div>
              <div>
                <p className="font-semibold">Office Address</p>
                <p className="mt-2">{globalJson.address}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="max-w-6xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-4 bg-[#EEEEEE] px-8 lg:px-16  py-12 rounded-md mb-12">
            <div className="max-w-sm space-y-0 lg:space-y-8">
              <h2 className="text-4xl py-4 font-semibold">
                We'd love to hear from you.
              </h2>
              <span>
                Our Rental Copmany can help you assess your daily rental needs
                in afforadable rates.
              </span>
              <h2 className="text-xl py-4 font-semibold">Your benefits:</h2>
              <div className="max-w-4xl mx-auto grid grid-cols-1 sm:grid-cols-2 gap-4">
                {features.map((feature, index) => (
                  <div key={index} className="flex items-center space-x-3">
                    {/* Checkmark Icon */}
                    <div className="w-6 h-6 flex justify-center items-center  rounded-full">
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="1.03735"
                          width="19"
                          height="19"
                          rx="9.5"
                          stroke="#2873AC"
                        />
                        <path
                          d="M14.52 7.61735C14.6 7.69735 14.64 7.79069 14.64 7.89735C14.64 8.00402 14.6 8.09735 14.52 8.17735L9.28 13.4174C9.2 13.4974 9.10667 13.5374 9 13.5374C8.89333 13.5374 8.8 13.4974 8.72 13.4174L6.12 10.7774C6.04 10.724 6 10.644 6 10.5374C6 10.4307 6.03333 10.3374 6.1 10.2574C6.16667 10.1774 6.25333 10.1374 6.36 10.1374C6.46667 10.1374 6.56 10.1774 6.64 10.2574L9 12.6174L13.96 7.61735C14.04 7.56402 14.1333 7.53735 14.24 7.53735C14.3467 7.53735 14.44 7.56402 14.52 7.61735Z"
                          fill="#2873AC"
                        />
                      </svg>
                    </div>
                    {/* Feature Text */}
                    <p className="text-[#12121299] text-md">{feature.text}</p>
                  </div>
                ))}
              </div>
            </div>

            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={sendEmail}>
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    placeholder="Example Doe"
                    type="text"
                    className="mt-1"
                    value={newName}
                    onChange={(e) => {
                      setNewName(e.target.value);
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    value={getEmail}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea
                    className="mt-1"
                    rows={6}
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                  />
                </label>
                <div>
                  <ButtonPrimary type="submit" className="min-w-[200px]">
                    {loading ? (
                      <div className="flex justify-center items-center col-span-full">
                        <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
                      </div>
                    ) : (
                      "Send Message"
                    )}
                  </ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="Pagecontact_" />
        </div>
        <SectionSubscribe2 className="py-24 lg:py-32" />
      </div> */}
    </div>
  );
};

export default PageContact;

import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { FooterLink } from "data/types";
import React from "react";
import { globalJson } from "global/global_json";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: FooterLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  // {
  //   id: "5",
  //   title: "Address",
  //   menus: [
  //     {
  //       // href: globalJson.addressLink,
  //       label: globalJson.address,
  //     },
  //   ],
  // },
  {
    id: "2",
    title: "Link",
    menus: [
      { href: "/about", label: "About Us" },
      { href: "/vehicles", label: "Our Fleet" },
      { href: "/contact", label: "Contact Us" },
    ],
  },
  {
    id: "1",
    title: "Contact Us",
    menus: [{ label: globalJson.phone }, { label: globalJson.email }],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm lg:ml-16 sm:ml-0">
        <h2 className="font-bold text-white dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              {item.href ? (
                <a
                  className="text-[#8A99B4] dark:text-neutral-300 hover:text-white dark:hover:text-white"
                  href={item.href}
                >
                  {item.label}
                </a>
              ) : (
                <span className="text-[#8A99B4] dark:text-neutral-300 break-words">
                  {item.label}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-8 border-t border-neutral-200 dark:border-neutral-700 bg-black">
      <div className="container grid grid-cols-1 gap-y-10 gap-x-5 sm:gap-x-8  lg:grid-cols-3 lg:gap-x-10 py-12 ">
        <div className="grid grid-cols-1 gap-5 col-span-2 md:col-span-3 lg:md:col-span-1 lg:flex lg:flex-col">
          <h2 className="text-white font-bold">About Us</h2>
          <span className="text-[#8A99B4]">
            Our Rental Copmany can help you assess your daily rental needs in
            afforadable rates.
          </span>
          {/* <div className="col-span-2 md:col-span-1">
            <Logo />
          </div> */}
          <div className="hidden lg:flex  items-center gap-3">
            {/* <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" /> */}
            <a href="https://www.facebook.com/">
              <svg
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="45" height="45" rx="22.5" fill="#2873AC" />
                <path
                  d="M26.2188 23.5H23.875V30.5H20.75V23.5H18.1875V20.625H20.75V18.4062C20.75 15.9062 22.25 14.5 24.5312 14.5C25.625 14.5 26.7812 14.7188 26.7812 14.7188V17.1875H25.5C24.25 17.1875 23.875 17.9375 23.875 18.75V20.625H26.6562L26.2188 23.5Z"
                  fill="white"
                />
              </svg>
            </a>
            <a href="https://www.instagram.com/">
              <svg
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="45" height="45" rx="22.5" fill="#2873AC" />
                <path
                  d="M22.5 18.9062C24.4688 18.9062 26.0938 20.5312 26.0938 22.5C26.0938 24.5 24.4688 26.0938 22.5 26.0938C20.5 26.0938 18.9062 24.5 18.9062 22.5C18.9062 20.5312 20.5 18.9062 22.5 18.9062ZM22.5 24.8438C23.7812 24.8438 24.8125 23.8125 24.8125 22.5C24.8125 21.2188 23.7812 20.1875 22.5 20.1875C21.1875 20.1875 20.1562 21.2188 20.1562 22.5C20.1562 23.8125 21.2188 24.8438 22.5 24.8438ZM27.0625 18.7812C27.0625 19.25 26.6875 19.625 26.2188 19.625C25.75 19.625 25.375 19.25 25.375 18.7812C25.375 18.3125 25.75 17.9375 26.2188 17.9375C26.6875 17.9375 27.0625 18.3125 27.0625 18.7812ZM29.4375 19.625C29.5 20.7812 29.5 24.25 29.4375 25.4062C29.375 26.5312 29.125 27.5 28.3125 28.3438C27.5 29.1562 26.5 29.4062 25.375 29.4688C24.2188 29.5312 20.75 29.5312 19.5938 29.4688C18.4688 29.4062 17.5 29.1562 16.6562 28.3438C15.8438 27.5 15.5938 26.5312 15.5312 25.4062C15.4688 24.25 15.4688 20.7812 15.5312 19.625C15.5938 18.5 15.8438 17.5 16.6562 16.6875C17.5 15.875 18.4688 15.625 19.5938 15.5625C20.75 15.5 24.2188 15.5 25.375 15.5625C26.5 15.625 27.5 15.875 28.3125 16.6875C29.125 17.5 29.375 18.5 29.4375 19.625ZM27.9375 26.625C28.3125 25.7188 28.2188 23.5312 28.2188 22.5C28.2188 21.5 28.3125 19.3125 27.9375 18.375C27.6875 17.7812 27.2188 17.2812 26.625 17.0625C25.6875 16.6875 23.5 16.7812 22.5 16.7812C21.4688 16.7812 19.2812 16.6875 18.375 17.0625C17.75 17.3125 17.2812 17.7812 17.0312 18.375C16.6562 19.3125 16.75 21.5 16.75 22.5C16.75 23.5312 16.6562 25.7188 17.0312 26.625C17.2812 27.25 17.75 27.7188 18.375 27.9688C19.2812 28.3438 21.4688 28.25 22.5 28.25C23.5 28.25 25.6875 28.3438 26.625 27.9688C27.2188 27.7188 27.7188 27.25 27.9375 26.625Z"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
        {/* <SocialsList1 className="lg:hidden flex items-start space-x-3 lg:flex-col lg:space-y-2.5 lg:items-start" /> */}
      </div>
    </div>
  );
};

export default Footer;

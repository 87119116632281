import rightImg from "images/image.png";
import rightImg2 from "images/image2.png";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHero3 from "components/SectionHero/SectionHero3";
import SectionHero from "containers/PageAbout/SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { globalJson } from "global/global_json";
import { useEffect } from "react";
import SectionHero2 from "containers/PageAbout/SectionHero2";
import WhyLevel305 from "components/why305/why305";
import ReadytoDrive from "./ReadytoDrive";
import readytodrive from "images/readytodrive.png";

function PageHome3() {
  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
  }, []);
  return (
    <>
      <div className="nc-PageHome3 relative overflow-hidden">
        {/* GLASSMOPHIN */}
        <BgGlassmorphism />
        {/* <DownloadLink /> */}

        {/* SECTION HERO */}
        {/* <div className="container relative lg:pb-16 lg:pt-8 md:pt-0 sm:pt-0 px-0 md:pb-0 sm:pb-0 "> */}
        <SectionHero3 className="" />

        {/* </div> */}
        <div className="container relative py-16">
          <SectionGridFeaturePlaces />
        </div>

        <div className="container relative" style={{ padding: "0px" }}>
          {/* ======== BG GLASS ======== */}
          {/* <BgGlassmorphism /> */}
          <div id="section">
            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
              <SectionHero
                rightImg={rightImg}
                heading="Welcome to Why Kings Car Rentals"
                btnText=""
                subHeading={globalJson?.about_us_para}
              />
            </div>
            <div className="container py-16  space-y-16 lg:space-y-28">
              <SectionHero2
                rightImg={rightImg2}
                heading="About Us"
                btnText=""
                subHeading="Hi, we are Dominique and Cade King. We have been married and lived in Ketchikan, Alaska for over 25 years. We loved raising our four children here, and as each one went on their way we found that our side hobby of renting cars, was something we wanted to get more involved in. We are a local business, supporting our community and those who are blessed to come visit here. Whether you are a local who temporarily needs a car, a traveler who is looking for a long term rental, or a tourist looking for the vacation of a lifetime we want to work with you. Please feel free to text with any concerns or questions you might have."
              />
            </div>
            <div className="px-4 lg:px-0 pb-28">
              <WhyLevel305 />
            </div>

            <div className="relative py-16">
              <BackgroundSection />
              <SectionClientSay uniqueClassName="PageAbout_" />
            </div>
            <div className="relative py-16 mt-32">
              <BackgroundSection className="bg-[#EEEEEE]" />
              <ReadytoDrive
                rightImg={readytodrive}
                heading="Book your ride today!"
                btnText=""
                subHeading="Ready to hit the road? Booking with Driven Enterprises is fast and simple. Browse our fleet online, choose your vehicle, and reserve it within minutes. For personalized assistance, feel free to contact our team directly."
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHome3;
